import React from "react";
import AsyncSelect from "react-select/async";
import { useApiGet, useApiLoading } from "react-reqq";
import { debounce, get } from "lodash";
import { removeEmpty } from "modules/common/helper";
import PropTypes from "prop-types";
import { GET_SUBSCRIBER_BY_REF_NUMBER } from "modules/partials/constants";
import * as actions from "./actions";

const INIT_FORM = {
  province: "",
  municipality: "",
  barangay: "",
  full_address: "",
  street: "",
  zip_code: "",
};

function AddressPicker({ value, onChange, required, errors }) {
  const [form, setForm] = React.useState({
    ...INIT_FORM,
    ...value,
  });

  const [labelIsOnTop, setLabelIsOnTop] = React.useState({
    province: !!value.province,
    municipality: !!value.municipality,
    barangay: !!value.barangay,
  });

  const [valueHolder, setValueHolder] = React.useState({
    province: value.province,
    municipality: value.municipality,
    barangay: value.barangay,
  });

  const [provinces, setProvinces] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [barangays, setBarangays] = React.useState([]);

  const subscriber = useApiGet(GET_SUBSCRIBER_BY_REF_NUMBER, {});
  const subscriber_id = subscriber?.included?.subscriber?.id;

  const provinceLoading = useApiLoading("GET_PROVINCE_LIST", "get");
  const municipalityLoading = useApiLoading("GET_MUNICIPALITY_LIST", "get");
  const barangayLoading = useApiLoading("GET_BARANGAY_LIST", "get");

  const selectRefProvince = React.useRef(null);
  const selectRefMunicipality = React.useRef(null);
  const selectRefBarangay = React.useRef(null);

  const provinceLoadOption = async (input = "", cb) => {
    const args = {
      q: input,
    };
    await actions.GET_PROVINCE_LIST(
      subscriber_id,
      removeEmpty(args),
      (data) => {
        cb(data);
      }
    );
  };

  const citiesLoadOption = async (input = "", cb) => {
    const args = {
      q: input,
    };
    await actions.GET_MUNICIPALITY_LIST(
      subscriber_id,
      get(form, "province.value"),
      removeEmpty(args),
      (data) => {
        cb(data);
      }
    );
  };

  const barangayLoadOption = async (input = "", cb) => {
    const args = {
      q: input,
    };
    await actions.GET_BARANGAY_LIST(
      subscriber_id,
      get(form, "province.value"),
      get(form, "municipality.value"),
      removeEmpty(args),
      (data) => {
        cb(data);
      }
    );
  };

  const handleFocus = (key) => () => {
    if (!labelIsOnTop[key]) {
      setLabelIsOnTop({
        ...labelIsOnTop,
        [key]: true,
      });

      if (key === "province") selectRefProvince.current.focus();
      if (key === "municipality") selectRefMunicipality.current.focus();
      if (key === "barangay") selectRefBarangay.current.focus();
    }
  };

  const handleBlur = (key) => () => {
    if (!valueHolder[key]) {
      setLabelIsOnTop({
        ...labelIsOnTop,
        [key]: false,
      });
    }
  };

  const handleOnChangeSelect = (key) => (item) => {
    let newForm = {
      ...form,
      [key]: item,
    };

    setValueHolder({
      ...valueHolder,
      [key]: item,
    });

    if (!item) {
      setLabelIsOnTop({
        ...labelIsOnTop,
        [key]: false,
      });
    }

    if (key === "province") {
      newForm = {
        ...newForm,
        municipality: "",
        barangay: "",
        zip_code: "",
        [key]: item,
      };

      setLabelIsOnTop({
        ...labelIsOnTop,
        municipality: false,
        barangay: false,
      });
    }

    if (key === "municipality") {
      newForm = {
        ...newForm,
        barangay: "",
        zip_code: "",
        [key]: item,
      };

      setLabelIsOnTop({
        ...labelIsOnTop,
        barangay: false,
      });
    }

    if (key === "barangay") {
      newForm = {
        ...newForm,
        [key]: item,
        zip_code: get(item, "data.attributes.zip_code"),
      };
    }

    setForm((prevState) => ({
      ...prevState,
      ...newForm,
    }));

    onChange(newForm);
  };

  const handleOnChange = (e) => {
    const newForm = {
      ...form,
      [e.target.name]: e.target.value,
    };

    setForm((prevState) => ({
      ...prevState,
      ...newForm,
    }));

    onChange(newForm);
  };

  const province_code = React.useMemo(() => {
    return get(form, "province.value") || false;
  }, [form]);

  const municipality_code = React.useMemo(() => {
    return get(form, "municipality.value") || false;
  }, [form]);

  React.useEffect(() => {
    if (province_code && subscriber_id) {
      actions.GET_MUNICIPALITY_LIST(
        subscriber_id,
        province_code,
        {},
        (data) => {
          setCities(data);
        }
      );
    }
  }, [province_code, subscriber_id]);

  React.useEffect(() => {
    if (municipality_code && subscriber_id) {
      actions.GET_BARANGAY_LIST(
        subscriber_id,
        province_code,
        municipality_code,
        {},
        (data) => {
          setBarangays(data);
        }
      );
    }
  }, [municipality_code, province_code, subscriber_id]);

  React.useEffect(() => {
    if (subscriber_id) {
      actions.GET_PROVINCE_LIST(
        subscriber_id,
        {
          per_page: 1000,
        },
        (data) => {
          setProvinces(data);
        }
      );
    }
  }, [subscriber_id]);

  const barangayNonServiceable = () => {
    if (cities.length > 0 && barangays.length === 0) return true;
    return false;
  };
  const renderPlaceHolder = {
    133901000: "Type barangay number",
    default: barangayNonServiceable
      ? "Non serviceable Area"
      : "Search barangay",
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div className="w-full relative">
          <div className="floating-form-select">
            <AsyncSelect
              cacheOptions
              defaultOptions={provinces}
              isSearchable
              isLoading={provinceLoading && form?.province === ""}
              isDisabled={provinceLoading}
              placeholder={labelIsOnTop?.province ? "Search Province..." : ""}
              classNamePrefix="react-select"
              className="react-select-container"
              ref={selectRefProvince}
              loadOptions={debounce(provinceLoadOption, 1000)}
              onChange={handleOnChangeSelect("province")}
              value={form?.province ?? ""}
              onFocus={handleFocus("province")}
              onBlur={handleBlur("province")}
              openMenuOnFocus
            />
            <label
              htmlFor="selectProvince"
              className="floating-form-select-label"
              onClick={handleFocus("province")}
              role="presentation"
              style={{
                transform: !labelIsOnTop?.province
                  ? "translate3d(0, 0, 0) scale3d(1, 1, 1)"
                  : "translate3d(0, -17px, 0) scale3d(0.7, 0.7, 1)",
              }}
            >
              Province {required && <span className="text-red-600">*</span>}
            </label>
          </div>
          {errors?.province && (
            <small className="flex text-xxs absolute bottom-2 items-center gap-1 right-0 px-2 bg-red-50 rounded text-red-500">
              <i className="fa fa-exclamation-circle" />{" "}
              {errors?.province?.message ?? ""}
            </small>
          )}
        </div>
        <div className="w-full relative">
          <div className="floating-form-select">
            <AsyncSelect
              cacheOptions
              defaultOptions={cities}
              ref={selectRefMunicipality}
              isLoading={
                municipalityLoading && get(form, "municipality") === ""
              }
              classNamePrefix="react-select"
              className="react-select-container"
              placeholder={labelIsOnTop?.province ? "Search City..." : ""}
              isDisabled={form.province === "" || municipalityLoading}
              loadOptions={debounce(citiesLoadOption, 1000)}
              onChange={handleOnChangeSelect("municipality")}
              value={form?.municipality ?? ""}
              onFocus={handleFocus("municipality")}
              onBlur={handleBlur("municipality")}
              openMenuOnFocus
            />
            <label
              htmlFor="selectCity"
              className="floating-form-select-label"
              onClick={handleFocus("municipality")}
              role="presentation"
              style={{
                transform: !labelIsOnTop?.municipality
                  ? "translate3d(0, 0, 0) scale3d(1, 1, 1)"
                  : "translate3d(0, -17px, 0) scale3d(0.7, 0.7, 1)",
              }}
            >
              City Municipality{" "}
              {required && <span className="text-red-600">*</span>}
            </label>
          </div>
          {errors?.municipality && (
            <small className="flex text-xxs absolute bottom-2 items-center gap-1 right-0 px-2 bg-red-50 rounded text-red-500">
              <i className="fa fa-exclamation-circle" />{" "}
              {errors?.municipality?.message ?? ""}
            </small>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <div className="w-full relative">
          <div className="floating-form-select">
            <AsyncSelect
              cacheOptions
              defaultOptions={barangays}
              loadOptions={debounce(barangayLoadOption, 1000)}
              ref={selectRefBarangay}
              isLoading={barangayLoading && get(form, "barangay") === ""}
              placeholder={
                labelIsOnTop?.barangay
                  ? renderPlaceHolder[form?.municipality?.value] ||
                    renderPlaceHolder.default
                  : ""
                // barangayNonServiceable
              }
              classNamePrefix="react-select"
              className="react-select-container"
              isDisabled={form.municipality === "" || barangayLoading}
              onChange={handleOnChangeSelect("barangay")}
              value={form?.barangay ?? ""}
              onFocus={handleFocus("barangay")}
              onBlur={handleBlur("barangay")}
              openMenuOnFocus
            />
            <label
              htmlFor="selectCity"
              className="floating-form-select-label"
              onClick={handleFocus("barangay")}
              role="presentation"
              style={{
                transform: !labelIsOnTop?.barangay
                  ? "translate3d(0, 0, 0) scale3d(1, 1, 1)"
                  : "translate3d(0, -17px, 0) scale3d(0.7, 0.7, 1)",
              }}
            >
              Barangay {required && <span className="text-red-600">*</span>}{" "}
              {barangayNonServiceable() && form?.municipality && (
                <span className="text-red-600">Non-serviceable</span>
              )}
            </label>
          </div>
          {errors?.barangay && (
            <small className="flex text-xxs absolute bottom-2 items-center gap-1 right-0 px-2 bg-red-50 rounded text-red-500">
              <i className="fa fa-exclamation-circle" />{" "}
              {errors?.barangay.message ?? ""}
            </small>
          )}
        </div>
        <div className="relative">
          <div className="floating-form">
            <input
              id="zip_code"
              type="text"
              className="floating-input"
              autoComplete="off"
              onChange={handleOnChange}
              disabled
              value={get(form, "zip_code") || ""}
              name="zip_code"
            />
            <label className="floating-label" htmlFor="zip_code">
              Zip Code {required ? <span className="text-red-500">*</span> : ""}
            </label>
          </div>
          {errors?.zip_code && (
            <small className="flex text-xxs absolute bottom-2 items-center gap-1 right-0 px-2 bg-red-50 rounded text-red-500">
              <i className="fa fa-exclamation-circle" />{" "}
              {errors?.zip_code?.message ?? ""}
            </small>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-3">
        <div className="w-full relative">
          <div className="floating-form">
            <input
              id="full_address"
              type="text"
              className="floating-input uppercase"
              autoComplete="off"
              onChange={handleOnChange}
              placeholder="Full Address Details"
              value={form?.full_address ?? ""}
              name="full_address"
            />
            <label className="floating-label" htmlFor="full_address">
              Full Address Details{" "}
              {required ? <span className="text-red-500">*</span> : ""}
            </label>
          </div>
          {errors?.full_address && (
            <small className="flex text-xxs absolute bottom-2 items-center gap-1 right-0 px-2 bg-red-50 rounded text-red-500">
              <i className="fa fa-exclamation-circle" />{" "}
              {errors?.full_address?.message ?? ""}
            </small>
          )}
        </div>
        <div className="w-full relative">
          <div className="floating-form">
            <input
              id="street"
              type="text"
              className="floating-input uppercase"
              autoComplete="off"
              onChange={handleOnChange}
              placeholder="Street Name"
              value={form?.street ?? ""}
              name="street"
            />
            <label className="floating-label" htmlFor="street">
              Street Name{" "}
              {required ? <span className="text-red-500">*</span> : ""}
            </label>
          </div>
          {errors?.street && (
            <small className="flex text-xxs absolute bottom-2 items-center gap-1 right-0 px-2 bg-red-50 rounded text-red-500">
              <i className="fa fa-exclamation-circle" />{" "}
              {errors?.street?.message ?? ""}
            </small>
          )}
        </div>
      </div>
    </>
  );
}
AddressPicker.defaultProps = {
  value: {},
  required: false,
  errors: false,
};

AddressPicker.propTypes = {
  onChange: PropTypes.instanceOf(Function).isRequired,
  value: PropTypes.instanceOf(Object),
  required: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
};

export default AddressPicker;
