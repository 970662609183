import React from "react";
import ToastMarker from "modules/common/toast";
import ErrorBoundary from "modules/common/components/ErrorBoundary";
import PartialContainer from "modules/partials/PartialContainer";
import { ModalMarker, AlertMarker } from "modules/common/modal";
import { InitiateGA } from "modules/common/components/GoogleAnalytics";

function App() {
  InitiateGA();
  return (
    <ErrorBoundary>
      <ToastMarker />
      <AlertMarker />
      <ModalMarker />
      <PartialContainer />
    </ErrorBoundary>
  );
}

export default React.memo(App);
